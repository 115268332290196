import type { Sponsor } from '@etf1-interne/tf1info_types_news/generics/sponsor'

import { mixins, theme } from '../../../styles'
import { IPicture } from '../../../types/pictures'
import { PictureElementList } from '../../atoms/PictureElementList'
import { HeaderTitleBlock } from '../HeaderTitleBlock'
import { SponsorBanner } from '../SponsorBanner'

export interface IEventCategoryHeader {
  description: string
  pictures?: IPicture
  title: string
  sponsor: Sponsor | null
}

export function EventCategoryHeader({
  pictures,
  title,
  description,
  sponsor,
}: IEventCategoryHeader): JSX.Element {
  return (
    <>
      <div className="EventCategoryHeader">
        {pictures && (
          <div className="EventCategoryHeader__Image">
            <PictureElementList
              {...pictures}
              lazyload={false}
              importance="high"
              fetchPriority="high"
              withPreload
            />
          </div>
        )}

        <HeaderTitleBlock
          title={title}
          subtitle={description}
          color={theme.cssVars.white}
          backgroundColor={theme.cssVars.deepBlue}
        />
        {sponsor && <SponsorBanner {...sponsor} withPreload theme="dark" />}
      </div>

      <style jsx>{`
        .EventCategoryHeader__Image {
          position: relative;
          overflow: hidden;
          height: 375px;
        }

        .EventCategoryHeader__Image :global(picture) {
          width: 100%;
          height: 100%;
        }

        .EventCategoryHeader__Image :global(img),
        .EventCategoryHeader__Image :global(svg) {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .EventCategoryHeader :global(.SponsorBanner) {
          padding-bottom: 50px;
        }

        @media ${mixins.mediaQuery.tablet} {
          .EventCategoryHeader :global(.SponsorBanner) {
            padding-bottom: 70px;
          }

          .EventCategoryHeader__Image {
            height: 460px;
            max-width: 2500px;
          }
        }
      `}</style>
    </>
  )
}
